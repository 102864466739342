import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import styles from "./index.module.scss";

import { getObjectByName } from "../../../../enums/CurrencyEnum";
import { getByCode, LanguageEnum } from "../../../../enums/LanguageEnum";

import ArrowLeftImg from "../../../../assets/presentation/images/arrow-left.svg";
import ArrowRightImg from "../../../../assets/presentation/images/arrow-right.svg";
import Caption from "../../Caption";
import H1 from "../../H1";
import Body from "../../Body";
import OrderDetail from "../OrderDetail";
import OrderImg from "../../../../assets/presentation/images/order.svg";
import LockImg from "../../../../assets/presentation/images/lock.svg";
import Security from "../Security";
import Language from "../Language";

function Menu(props) {
    const [isOrderDetailVisible, setIsOrderDetailVisible] = useState(false);
    const [isLanguageVisible, setIsLanguageVisible] = useState(false);
    const [isSecurityVisible, setIsSecurityVisible] = useState(false);

    const intl = useIntl();
    const language = useSelector((state) => state.language.language);

    if (isOrderDetailVisible)
        return <OrderDetail 
            data={props.data}
            setVisibility={setIsOrderDetailVisible} 
        />;

    if (isSecurityVisible)
        return <Security 
            data={props.data}
            setVisibility={setIsSecurityVisible} 
        />;

    if (isLanguageVisible)
        return <Language 
            data={props.data}
            setVisibility={setIsLanguageVisible} 
        />;

    const showSummary = () => {
        if (props.data.onlyLanguage === true) {
            return <></>
        }
        return <div className={styles.summary}>
            <Caption>
                {intl.formatMessage({ id: "global.presentation.menu.title" })}
            </Caption>
            <H1>
                {`${props.data.totalAmount / 100} ${getObjectByName(props.data.currency).symbol}`}
            </H1>
            <div className={styles.hr}></div>
            <Caption>
                {`${intl.formatMessage({ id: "global.presentation.menu.transactionId" })}: ${props.data.transactionId}`}
            </Caption>
            <Caption>
                {`${intl.formatMessage({ id: "global.presentation.menu.orderId" })}: ${props.data.orderNumber}`}
            </Caption>
        </div>
    }

    const showOrderDetail = () => {
        if (props.data.onlyLanguage === true) {
            return <></>
        }
        return <div
            className={styles.option}
            onClick={() => setIsOrderDetailVisible(true)}
        >
            <div className={styles.left}>
                <img src={OrderImg} alt="order" />
            </div>
            <Body semiBold>
                {intl.formatMessage({ id: "global.presentation.menu.orderDetail" })}
            </Body>
            <div className={styles.right}>
                <img src={ArrowRightImg} alt="arrow-right" />
            </div>
        </div>
    }

    const showSecurity = () => {
        if (props.data.onlyLanguage == true) {
            return <></>
        }
        return <div
            className={styles.option}
            onClick={() => setIsSecurityVisible(true)}
        >
            <div className={styles.left}>
                <img src={LockImg} alt="lock" />
            </div>
            <Body semiBold>
                {intl.formatMessage({ id: "global.presentation.menu.security" })}
            </Body>
            <div className={styles.right}>
                <img src={ArrowRightImg} alt="arrow-right" />
            </div>
        </div>
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div
                    onClick={() => props.setVisibility(false)}
                    className={styles.left}
                >
                    <img
                        src={ArrowLeftImg}
                        alt="back"
                    />
                    <Caption opacity={0.4}>
                        {intl.formatMessage({ id: "global.presentation.back" })}
                    </Caption>
                </div>
            </div>
            <div className={styles["content-container"]}> 
                <div className={styles.content}>
                    {showSummary()}
                    <div className={styles.options}>
                    {showOrderDetail()}
                    {showSecurity()}
                        <div
                            className={styles.option}
                            onClick={() => setIsLanguageVisible(true)}
                        >
                            <div className={styles.left}>
                                {getByCode(language).logo}
                            </div>
                            <Body semiBold>
                                {intl.formatMessage({ id: "global.presentation.menu.language" })}
                            </Body>
                            <div className={styles.right}>
                                <img src={ArrowRightImg} alt="arrow-right" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;